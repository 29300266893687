import React from 'react';
import "./ContactForm.css"

const ContactForm = () => {
    return (
        <div className='contactBanner text-white'>
            <div className='container p-14 mx-auto grid grid-cols-1 lg:grid-cols-2 gap-8 justify-center items-center'>
                <div className='text-left flex flex-col justify-center contactText'>
                    <div className='contact'>
                        <div className='text-white mb-4'>
                            <h2 className='text-2xl font-bold mt-14 lg:mt-0'>PRIDE SHIPPING LINES</h2>
                            <h2>Akhtaruzzaman Center (10th Floor) <br/>
                                21/22, Agrabad Commercial Area <br/>
                                Chattogram - 4100, Bangladesh.</h2>
                        <div className=' mb-4'>
                            <h2 className='text-2xl font-bold'>Office Hours</h2>
                            <h2>Saturday to Thursday : 09:00 – 17:00 hrs Friday - Close</h2>
                        </div>
                        </div>
                        <div className=' mb-4'>
                            <h2 className='text-2xl font-bold'>Email</h2>
                            <a href="mailto: operations@prideshippingbd.com">operations@prideshippingbd.com</a>
                           
                        </div>
                        <div className='mb-4'>
                            <h2 className='text-2xl font-bold'>Phone Numbers</h2>
                            <h2><a href="tel:+880 233 3317866">+880 233 3317866</a></h2>
                            <h2><a href="tel:+880 233 3320774">+880 233 3320774</a></h2>
                            <h2><a href="tel:+8801788343586">+8801788343586</a></h2>
                           
                          
                        </div>
                   </div>
                </div>
                <div className='lg:text-left'>
                    <h2 className='text-4xl font-extrabold'>Let's Talk</h2>
                    <p>Get Connected To Us</p>
                    <input type="text" placeholder='Enter Name' className='px-6 py-3 mt-7 rounded-lg lg:w-96' required/> <br />
                    {/* <hr  className='mt-6 lg:w-[500px]'/> */}
                    <input type="phn" placeholder='Enter Phone Number' className='px-6 py-3 mt-7 rounded-lg lg:w-96' required/> <br />
                    {/* <hr  className='mt-6 lg:w-[500px]'/> */}
                    <input type="Email" placeholder='Enter Email Id' className='px-6 py-3 mt-7 rounded-lg lg:w-96' required/> <br />
                    {/* <hr className='mt-6 lg:w-[500px]' /> */}
                    <textarea name="" id="" cols="30" rows="3" className='p-8 mt-7 mb-7 rounded-lg hidden lg:block' placeholder='Enter Message'></textarea>
                    <button type="submit" className='btn btn-success mt-4'>Send Us</button>
                </div>
           </div>
        </div>
    );
};

export default ContactForm;