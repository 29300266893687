import React from 'react';
import { fleets } from '../../../data/fleetdata';
import FleetDetailsCard from '../FleetDetailsCard/FleetDetailsCard';

const FleetDetails = () => {
    return (
        <div className='mt-14 mb-14 p-6'>
            <div>
                <div className='container mx-auto'>
                    {
                        fleets?.map(fleet => <FleetDetailsCard key={fleet.id} fleet={fleet} />)
                    }
                </div>
           </div>
        </div>
    );
};

export default FleetDetails;