import ship from "../assets/icons/cargo-ship.png"
import experts from "../assets/icons/leader.png"
import seaport from "../assets/icons/seaport.png"
import customercare from "../assets/icons/24-hours-support.png"

export const descriptions = [
    {
        "id": 1,
        "img": ship,
        "name": "Ship Handeling",
        "details" : "Pride Shipping Lines handles the maximum number of vessels in  Bangladesh. Our expertise ranges from refined petroleum products, LPG, LNG, Wheat, Fertilizer, and etc. We are expert both in dry and wet cargo"
    },
    {
        "id": 2,
        "img": experts,
        "name": "Experts We Have",
        "details" : "Our in-house experts make the vessel handling simple. We ensure the quickest turn around of the vessel"
    },
    {
        "id": 3,
        "img": seaport,
        "name": "Seaports",
        "details" : "Pride Shipping Lines is present in all seaports of Bangladesh. With the most expansive network in strategic locations, we ensure our principle's interests are handled in the most efficient way"
    },
    {
        "id": 4,
        "img": customercare,
        "name": "24/7 Services",
        "details" : "Our office is open 24/7 in 365 days in a year. Since, we handle the most number of vessels in Bangladesh, our team is working around the clock throughout the year. There is always someone who is ready to jump to do whatever it takes to get the job done"
    }
]