import React from 'react';
import "./AboutPride.css"

const AboutPride = () => {
    return (
        <div className='container mx-auto'>
            <div className='aboutusContainer'>
                <div className='p-6  aboutTextContainer'>
                    <h2 className='font-bold text-4xl mb-8'>ABOUT PRIDE SHIPPING</h2>
                    <p >Pride Shipping Line is a privately-held company providing high quality service to the owners worldwide. We focus on long-term relationship based on hard work, ethics and compliance. From aone-man army at its inception, Pride Shipping has grown to become a leading shipping agency and has become one of the most recognized shipping agents in Bangladesh. Pride Shipping Lines is a member of Bangladesh Shipping Agents Association. Pride Shipping eases owners’ burden to source for different service by working as a one stop service provider in all seaports of Bangladesh.</p>
               </div>
            </div>
        </div>
    );
};

export default AboutPride;