import React from 'react';
import ContactForm from '../../Component/ContactComponent/ContactForm/ContactForm';

const ContactPage = () => {
    return (
        <div>
            <ContactForm/>
        </div>
    );
};

export default ContactPage;