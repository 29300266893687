import React from 'react';
import "./FleetBanner.css"

const FleetBanner = () => {
    return (
        <div>
           <div className='fleetContainer'>
                <div className='text-white font-bold text-4xl fleetText container mx-auto p-6 lg:p-24'>
                    <h2>Want To Know About Our Fleet</h2>
               </div>
            </div>
        </div>
    );
};

export default FleetBanner;