import React from 'react';
import visionImg from "../../../assets/images/banner/ship2.jpg"

const Vision = () => {
    return (
        <div>
             <div className= 'container mx-auto'>
            <div className='flex flex-col lg:flex-row justify-center items-center gap-20 text-left p-6'>
                <div className='lg:w-[900px] '>
                    <h2 className='font-bold text-4xl mb-10'>Our Vission</h2>
                    <p>Our vision is to help our principal to achieve its goals. In these challenging times, it is more important than ever for the owners and suppliers to have strong roots in the local market. With the experience of 42 years, we are in position to handle your interest in Bangladesh in an integrated manner for shipping, logistics, and marine sector. Our broad range of expertise enables us to deliver customized service experience to our clients.</p>
                </div>
                <div>
                    <img className='lg:w-[1000px]' src={visionImg} alt="" />
                </div>
           </div>
        </div>
        </div>
    );
};

export default Vision;