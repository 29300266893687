import React, {useState} from 'react';
import {services} from '../../../data/services';
import OurServiceCard from '../../HomeComponent/OurServiceCard/OurServiceCard';

import {GiBulletImpacts} from "react-icons/gi";


const OurServices = () => {
    const [servicedetails] = useState()
    console.log(servicedetails)


    return (
        <div className='mb-10'>
            <h2 className='text-2xl font-bold'>Our Services</h2>
            <h2 className='text-4xl mt-6 font-bold'>Pride Shipping's Services</h2>
            <div
                className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 justify-center items-center container mx-auto p-6 mt-10'>
                {
                    services?.map(service => <OurServiceCard service={service} key={service.id}/>)
                }
                {/*<ServiceCardDetail servicedetails={servicedetails} />*/}
            </div>
            <div className={'flex justify-center'}>
                <div className={'container text-left p-6'}>
                    <h2 className='text-4xl font-bold mb-3'>Other Services</h2>
                    <p className='mb-3'>Pride Shipping Lines also provides other services continuously to the vessel and its owners or
                        its
                        representative. With all these services, we work as one-stop service provider for our principals
                        in
                        order to reduce any hassle from their end. Our services include, but not limited to below:</p>
                    <ul type={'square'}>
                        <li className={'flex items-center font-bold'}><GiBulletImpacts
                            style={{color: "green", fontSize: 20, marginRight: 10}}/> Ship Spares Delivery
                        </li>
                        <li className={'flex items-center font-bold'}><GiBulletImpacts
                            style={{color: "green", fontSize: 20, marginRight: 10}}/>Supply of provisions, deck, and
                            engine stores
                        </li>
                        <li className={'flex items-center font-bold'}><GiBulletImpacts
                            style={{color: "green", fontSize: 20, marginRight: 10}}/>Bunker co-ordination- Supply,
                            quality and quantity survey
                        </li>
                        <li className={'flex items-center font-bold'}><GiBulletImpacts
                            style={{color: "green", fontSize: 20, marginRight: 10}}/>Lube oil supply
                        </li>
                        <li className={'flex items-center font-bold'}><GiBulletImpacts
                            style={{color: "green", fontSize: 20, marginRight: 10}}/>De-bunkering: off-specification
                            bunker
                        </li>
                        <li className={'flex items-center font-bold'}><GiBulletImpacts
                            style={{color: "green", fontSize: 20, marginRight: 10}}/>Gas carrier
                        </li>
                        <li className={'flex items-center font-bold'}><GiBulletImpacts
                            style={{color: "green", fontSize: 20, marginRight: 10}}/>Aerial Photography
                        </li>
                        <li className={'flex items-center font-bold'}><GiBulletImpacts
                            style={{color: "green", fontSize: 20, marginRight: 10}}/>Underwater diving services
                        </li>
                        <li className={'flex items-center font-bold'}><GiBulletImpacts
                            style={{color: "green", fontSize: 20, marginRight: 10}}/>Emergency tug services
                        </li>
                        <li className={'flex items-center font-bold'}><GiBulletImpacts
                            style={{color: "green", fontSize: 20, marginRight: 10}}/>Fresh Water Supply
                        </li>
                        <li className={'flex items-center font-bold'}><GiBulletImpacts
                            style={{color: "green", fontSize: 20, marginRight: 10}}/>Nitrogen purging and other gas
                            supply
                        </li>
                    </ul>
                </div>
            </div>

        </div>
    );
};

export default OurServices;