import React from 'react';
import {Link} from 'react-router-dom';


const OurServiceCard = ({service, setServiceDetails, servicedetails}) => {
    const {name, details, icon, id} = service
    // console.log(setServiceDetails)

    return (
        <div className="card h-full bg-base-100 shadow-xl p-10">
            <img src={icon} className="w-[100px] block mx-auto border shadow-lg mb-4 rounded-full p-4" alt=""/>
            <div className={'p-2'}>
                <h2 className="card-title justify-center text-2xl font-bold mt-4 mb-4">{name}</h2>
                <p className={'textClass'}>{details}</p>
                <div className='text-center'>
                    <Link to={`/servicedetail/${id}`}>
                        <button className='btn btn-success'>Read More
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default OurServiceCard;