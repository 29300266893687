import bag from "../assets/icons/briefcase.png"
import man from "../assets/icons/working.png"
import group from "../assets/icons/couple.png"


export const services = [
    {
        "id": 1,
        "img": bag,
        "title": "40+",
        "subtitle" : "Years Experience"
        
    },
    {
        "id": 2,
        "img": man,
        "title": "100+",
        "subtitle" : "Repeated Clients"

    },
    {
        "id": 3,
        "img": group,
        "title": "50+",
        "subtitle" : "Shipping Experts"
    }
]