import React from 'react';

const FleetDetailsCard = ({ fleet }) => {
    const {img, name, details} = fleet
    return (
        <div className='flex flex-col lg:flex-row items-center justify-center gap-10 '>
            <div>
                <img src={img} alt="" className='lg:w-[550px] mb-6'/>
            </div>
            <div className=' lg:text-left'>
                <h2 className='text-2xl lg:text-3xl font-bold mb-6'>{name}</h2>
                <h2 className='lg:w-[500px] mb-6'>{details}</h2>
            </div>
        </div>
    );
};

export default FleetDetailsCard;