import React from 'react';
import FleetBanner from '../../Component/FleetComponent/FleetBanner/FleetBanner';
import FleetDetails from '../../Component/FleetComponent/FleetDetails/FleetDetails';

const FleetPage = () => {
    return (
        <div>
            <FleetBanner />
            <FleetDetails/>
        </div>
    );
};

export default FleetPage;