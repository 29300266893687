import React from 'react';
import AboutBanner from '../../Component/AboutComponent/AboutBanner/AboutBanner';
import AboutPride from '../../Component/AboutComponent/AboutPride/AboutPride';
import Counter from '../../Component/AboutComponent/Counter/Counter';
import Description from '../../Component/AboutComponent/Description/Description';
import Mission from '../../Component/AboutComponent/Mission/Mission';
import Vision from '../../Component/AboutComponent/Vision/Vision';

const AboutPages = () => {
    return (
        <div>
            <AboutBanner />
            <Description />
            <Mission />
            <Vision />
            <AboutPride />
            <Counter/>
        </div>
    );
};

export default AboutPages;