import img1 from "../assets/photos/photo6-01.jpg"
import img2 from "../assets/photos/photo1-01.jpg"
import img3 from "../assets/photos/photo5-01.jpg"
import img4 from "../assets/photos/photo3-01.jpg"
import img5 from "../assets/photos/photo4-01.jpg"


export const teams = [
    {
        "id": 1,
        "img": img1,
        "name": "M N Islam",
        "designation": "Managing Partner"
        
    },
/*    {
        "id": 2,
        "img":img2,
        "name": "Alim Uddin Ahmed",
        "designation": "Executive Director"
    },*/
    {
        "id": 3,
        "img": img3,
        "name": "Shibu Prashad Bose",
        "designation": "Senior Manager"
    },
    {
        "id": 4,
        "img":img4,
        "name": "Md.Kamal Hossain Khondaker",
        "designation": "Manager Accounts & Finance"
    },
    {
        "id": 5,
        "img": img5,
        "name": "Mr.Shahe Mahmud",
        "designation": "Manager(Communication)"
    }
]