import React from 'react';
import { services } from '../../../data/services';
import OurServiceCard from '../OurServiceCard/OurServiceCard';
import Button from '../../Shared/Button/Button';
import { Link } from 'react-router-dom';


const OurServices = () => {

    return (
        <div className='mt-8 mb-10 p-5'>
            <h2 className='text-2xl font-bold text-gray-600'>Our Services</h2>
            <h2 className='text-4xl font-bold'>Pride Shipping's Services</h2>
            <p className='mt-4'>Proper Plan and Perfect Excution For The Quickest Turn Around of The Vessel</p>

            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 container mx-auto gap-4 justify-center items-center mt-14'>
                {
                    services?.slice(0, 3).map(service => <OurServiceCard service={service} key={service.id}/>)
                }
            </div>
          <Link to="/services"><Button>See All Services</Button></Link>
        </div>
    );
};

export default OurServices;