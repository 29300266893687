import React from 'react';
import logo from "../../../assets/logo/prideShippingLogo-01.png"
import {FaFacebook, FaWhatsapp, FaLinkedin} from "react-icons/fa";
import {Link} from 'react-router-dom';

const Footer = () => {
    return (
        <footer className='bg-black'>
            <div className="footer justify-center items-center lg:gap-60 p-10  text-white">
                <div className='flex items-start lg:justify-center lg:items-center flex-col'>
                    <div>
                        <img src={logo} alt="" className='w-[150px]'/></div>
                    {/* <div>
                        <p className='text-left lg:text-center lg:w-[450px]'>We are passionate of deep understanding of management and communication
                        and how to interact with the digital world is the true value we provide. We help
                            you in all the stages of the realization of your digital products.</p>
                    </div> */}
                </div>

                <div>
                    <span className="footer-title">Legal</span>
                    {/* <a className="link link-hover">Terms of use</a>  */}
                    <a className="link link-hover">Privacy policy</a>
                    <a className="link link-hover">Cookie policy</a>
                </div>
                <div>
                    <span className="footer-title">Social Links</span>
                    <div className="flex text-4xl gap-4">
                        {/*<FaFacebook/>*/}
                        <a href="https://wa.me/+8801788343586" target={'_blank'}><FaWhatsapp/></a>
                        <a href="https://www.linkedin.com/company/pride-shipping-lines/" target={'_blank'}><FaLinkedin/></a>
                        {/*<FaWhatsapp/>*/}
                        {/*<FaLinkedin/>*/}

                    </div>
                </div>
            </div>

            <small className='text-white block'>All Right Reserved By <Link to="/"><span
                className='font-bold text-green-500'>Pride Shipping Lines</span></Link></small>
            <small className='text-white p-2 block'>Powred by <a href="https://softimize.tech/" target="_blank"><span
                className='font-bold text-red-700'>SoftimizeTech</span></a> </small>
        </footer>
    );
};

export default Footer;