import React from 'react';
import { descriptions } from '../../../data/description';
import DescriptionDetails from './DescriptionDetails';

const Description = () => {
    return (
        <div className='mt-6 lg:mt-16 container mx-auto'>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-10 justify-items-center p-6'>
                {
                    descriptions?.map(description => <DescriptionDetails key={description.id} description={description} />)
                }
            </div>
           
        </div>
    );
};

export default Description;