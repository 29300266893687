import React from 'react';

const button = ({children}) => {
    return (
        <div>
            <button className='btn btn-success mt-6'>{children}</button>
        </div>
    );
};

export default button;