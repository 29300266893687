import React from 'react';
import missionImg from "../../../assets/images/banner/bnr2.jpg"

const Mission = () => {
    return (
        <div className='mt-10 lg:mt-18 container mx-auto'>
            <div className='flex flex-col lg:flex-row justify-center items-center gap-20 text-left p-6 lg:p-0'>
                <div>
                    <img src={missionImg} alt="" />
                </div>
                <div className='lg:w-[900px] '>
                    <h2 className='font-bold text-4xl mb-10'>Our Mission</h2>
                    <p>Our only mission is the quickest turn around of the vessel when they are staying at port. We understand the time value of money and  ensure our principal is not losing any money by staying any extra  time than the vessel should and sail for the next call as early as possible. We strictly comply with owner’s anti- bribery policy. As we stick to our mission, and our principals are entrusting us to handle their business over the years.</p>
                </div>
           </div>
        </div>
    );
};

export default Mission;