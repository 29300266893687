import React from 'react';

const DescriptionDetails = ({ description }) => {
    const {name, details, img} = description
    // console.log(name)
    return (
        <div className='hover:shadow-lg hover:bg-slate-100 p-6 lg:p-12 rounded-lg cursor-pointer'>
            <img className='w-[80px] block mx-auto' src={img} alt="" />
            <h2 className='text-3xl font-bold mt-4'>{name}</h2>
            <p className='mt-4'>{details}</p>
        </div>
    );
};

export default DescriptionDetails;