import React from 'react';
import { services } from '../../../data/data';
import { works } from '../../../data/works';
import WorkingCard from '../../WorkingCard/WorkingCard';
import "./WorkingProcess.css"

const WorkingProcess = () => {
    return (
        <div>
            <div className='workingContainer p-14'>
                <div className='workingTextContainer container mx-auto'>
                    <div className='lg:w-[900px]'>
                        <h2 className='text-xl lg:text-4xl font-bold text-white'>Amazing things happen to your business when we connect those dots of utility and value</h2>
                        {/* <p className='text-white mt-6'>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over</p> */}
                   </div>
                    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 container mx-auto justify-items-center gap-6 mt-16 '>
                        {
                            works?.map(work => <WorkingCard key={work.id} work={work} />)
                        }
                    </div>
                    <div className='mt-14 text-white'>
                        <div className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-20'>
                            {
                                services?.map(service => <>
                                    <div className='flex flex-col items-center'>
                                        <div className='flex items-center flex-row gap-3'>
                                                <img src={service.img} className="w-[60px]" alt="" />
                                                <h2 className='text-5xl'>{service.title}</h2>
                                                {/* <h2 className='text-5xl'><CountUp end={100} /></h2> */}
                                        </div>
                                        <div className='mt-4 text-xl'>
                                                <p>{service.subtitle}</p>
                                        </div>
                                    </div>
                                    
                                    </>)
                            }
                        </div>
                    </div>
                </div>
           </div>
        </div>
    );
};

export default WorkingProcess;