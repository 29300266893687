import React, {useEffect} from 'react';
import Contact from '../../Component/HomeComponent/Contact/Contact';
import OurServices from '../../Component/OurServiceComponent/OurServices/OurServices';
import ServiceBanner from '../../Component/OurServiceComponent/ServiceBanner/ServiceBanner';
import WorkingProcess from '../../Component/OurServiceComponent/WorkingProcess/WorkingProcess';

const ServicePage = () => {
    useEffect(()=>{
        window.scrollTo(0,0)
    }, [])
    return (
        <div>
            <ServiceBanner />
            <OurServices />
            <WorkingProcess />
            {/* <Contact/> */}
        </div>
    );
};

export default ServicePage;