import React from 'react';
import Banner from '../../Component/HomeComponent/Banner/Banner';
import About from '../../Component/HomeComponent/About/About';
import OurServices from '../../Component/HomeComponent/OurServices/OurServices';
import WhyUs from '../../Component/HomeComponent/WhyUs/WhyUs';
import Contact from '../../Component/HomeComponent/Contact/Contact';




const HomePage = () => {
    return (
        <div>
            <Banner />
            <About />
            <OurServices />
            <WhyUs />
            {/* <Clients/> */}
            {/* <Contact/> */}
        </div>
    );
};

export default HomePage;