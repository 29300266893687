import React from 'react';

const TeamCard = ({ team }) => {
    // console.log(team)
    const {img, name, designation} = team
    return (
        <div className="card bg-base-100 shadow-lg w-full">
            <div className="card-body">
                <img src={img} alt="" className='w-[350px] h-[350px] block mx-auto'/>
                <h2 className="text-3xl font-bold text-center mt-3">{name}</h2>
                <p>{designation}</p>
               
            </div>
      </div>
    );
};

export default TeamCard;