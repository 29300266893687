import React from 'react';
import "./WhyUs.css"

import Counters from '../../Counter/Counters';
// import CountUp from 'react-countup';


const WhyUs = () => {
    return (
        <div className=''>
            <div className='textContainer text-white'>
                <div className='texts p-6'>

                    <h2 className='text-4xl font-bold mb-4'>Why Pride Shipping Lines</h2>
                    <p className={'p-6 md:px-40 lg:px-72'}>
                        With more than 40 years of experience, Pride Shipping Lines is the most reliable agent to ensure
                        perfect coordination of the vessels. We have the widest service locations in Bangladesh and can
                        ensure service at any point in the country. Our clients always appreciates us by employing us
                        repeatedly whenever they need any support in Bangladesh.
                    </p>
                    <Counters/>
                </div>
            </div>
        </div>
    );
};

export default WhyUs;