import React from 'react';
import "./WorkingCard.css"

const WorkingCard = ({ work }) => {
    
    const {icon, title, description} = work

    return (
        <div className='text-white workingCardContainer'>
            <img src={icon} alt="" className='w-[50px] block mx-auto' />
            <h2 className='mt-6 text-3xl font-bold'>{title}</h2>
            <p className='mt-6'>{description}</p>
        </div>
    );
};

export default WorkingCard;