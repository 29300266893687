import React from 'react';
import "./ServiceBanner.css"

const ServiceBanner = () => {
    return (
        <div className='mb-10'>
            <div className='serviceContainer'>
                <div className='text-white font-bold text-4xl serviceText container mx-auto p-6 lg:p-24'>
                    <h2 className='text-4xl font-bold mb-8'>Our Services</h2>
                </div>
          </div>
        </div>
    );
};

export default ServiceBanner;