import img1 from "../assets/images/banner/thum2.18287b17.jpg"
import img2 from "../assets/images/banner/thum1.37c23ec1.jpg"
import img3 from "../assets/images/banner/bnr5.jpg"

export const fleets = [
    {
        "id": 1,
        "img": img1,
        "name": "Pride Of Amanat Shah",
        "details" : "Pride of Amanat Shah is engaged in carrying bulk products inside Bangladesh, This vessel carries clinker, whea gypsums, s alt for BCIC, private cement companies, DG Food, and several other entities."
    },
    {
        "id": 2,
        "img": img2,
        "name": "Pride Of Shahjalal",
        "details" : "Pride of Shahjalal is a tanker and is engaged with Padma oil company limited to carry petroleum products from its depot from Chittagong to different parts of the country. This vessel is first Bangladesh made double hull, double bottom tanker. The vessel usually makes 2-3 trips per month in the domestic routes of Bangladesh and playing important role in petroleum product distribution by carrying thousands of metric tons refined petroleum products in key destination."
    },
    {
        "id": 2,
        "img": img3,
        "name": "Pride Of Habib E Noor",
        "details" : "Pride of Habib is also a tanker and is engaged with Meghna oil company limited to carry petroleum products from its depot from Chittagong to different parts of the country. The vessel usually makes 2 trips per month in the domestic routes of Bangladesh and playing important role in petroleum product distribution by carrying thousands of metric tons refined petroleum products in key destination."
    }
]