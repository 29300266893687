import React from 'react';
import banner2 from "../../../assets/images/banner/bnr2.jpg"
import "./About.css"

const About = () => {
    return (
        <div className='mt-4 lg:mt-10 container mx-auto mb-10'>
            <div>
                <div className='flex flex-col lg:flex-row p-5 lg:ml-2 items-center justify-center'>
                    <div className='backgroundColor lg:shadow-xl lg:w-[1200px]  lg:-mr-32 z-10 p-6 rounded-lg mr-6'>
                        <div className='grid grid-cols-1 lg:grid-cols-2'>

                            <div className='flex flex-col items-start text-left mb-6'>
                                <h2 className='text-4xl font-extrabold'>About Us</h2>
                                <h4 className='text-xl'>We Create Unique Experience</h4>
                            </div>
                            <div>
                                <p className='text-justify'> Pride Shipping Line is a privately-held company providing high
                                    quality service to the owners worldwide. We focus on long-term relationship based on
                                    hard work, ethics and compliance. From a one-man army at its inception, Pride
                                    Shipping has grown to become a leading shipping agency and most recognized shipping agents in Bangladesh. Pride Shipping Lines is a member of Bangladesh Shipping Agent Association. Pride Shipping eases owners burden to source for different service by working as a one stop service provider in all seaports of Bangladesh.</p>
                            </div>
                        </div>


                    </div>
                    <div className='container'>
                        <img className='lg:max-w-full' src={banner2} alt=""/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;