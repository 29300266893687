import React from 'react';
import { teams } from '../../data/team';
import TeamCard from '../TeamCard/TeamCard';
import "./TeamDetails.css"

const TeamDetails = () => {
    return (
        <div>
             <div className='teamBanner'>
                <div className='container mx-auto'>
                    <h2 className='teamContent text-5xl font-bold flex justify-center lg:justify-start items-center'>Our Team</h2>
                </div>
            </div>
            <h2 className='text-5xl mt-14 font-bold'>Meet Our Team</h2>
                <div className='mt-16 mb-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 container mx-auto justify-items-center items-center p-6'>
                    {
                        teams?.map(team => <TeamCard team={team} key={team.id} />)
                    }
                </div>
       </div>
    );
};

export default TeamDetails;