import React from 'react';
import Counters from '../../Counter/Counters';

import "./Counter.css"

const Counter = () => {
    return (
        <div>
            <div className='counterContainer'>
                <Counters/>
            </div>
        </div>
    );
};

export default Counter;