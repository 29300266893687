import React from 'react';
import {services} from '../../data/services';
import {useParams} from "react-router-dom";


const ServiceCardDetail = () => {
    const {id} = useParams()
    const serviceDetail = services.find(el => el.id === (+id))
    console.log(serviceDetail)
    return (
        <div className={'py-20 mx-auto px-10'} style={{maxWidth: 850}}>
            <div className={'flex justify-center items-center'}>

                <img src={serviceDetail.icon} alt="" style={{maxWidth: 100, paddingRight: 10}}/>
                <p style={{
                    fontSize: 30,
                    fontWeight: 700,
                    borderRight: '3px solid #36D399',
                    borderLeft: '3px solid #36D399',
                    display: "inline-block",
                    padding: '0 10px'
                }}>{serviceDetail.name}</p>
            </div>
            <div>
                <p className={'py-10'}>{serviceDetail.details}</p>
            </div>
        </div>
    );
};

export default ServiceCardDetail;