import React, {useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import logo from "../../../assets/logo/prideShippingLogo-01.png"


const Navbar = () => {
    const [navColor, setnavColor] = useState("bg-transparent");
    const listenScrollEvent = () => {
        window.scrollY > 100 ? setnavColor("bg-black") : setnavColor("bg-transparent");
    };
    useEffect(() => {
        window.addEventListener("scroll", listenScrollEvent);
        return () => {
            window.removeEventListener("scroll", listenScrollEvent);
        };
    }, []);
    return (
        <div className={`top-0 ${navColor}`} style={{zIndex: 100, position: "fixed", width: "100%", transition:'all 0.5s ease-in-out'}}>
            <div className="navbar  container mx-auto  text-white">
                <div className="navbar-start">
                    <div className="dropdown">
                        <label tabIndex={0} className="btn btn-ghost lg:hidden">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24"
                                 stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                      d="M4 6h16M4 12h8m-8 6h16"/>
                            </svg>
                        </label>
                        <ul tabIndex={0}
                            className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52">
                            <Link to="/" className='text-black'>Home</Link>
                            <Link to="/about" className='text-black'>About</Link>
                            <Link to="/services" className='text-black'>Our Service</Link>
                            <Link to="/fleet" className='text-black'>Our Fleet</Link>
                            <Link to="/team" className='text-black'>Our Team</Link>
                        </ul>
                    </div>
                    <Link to="/"><img className='w-[100px]' src={logo} alt=""/></Link>
                </div>
                <div className="navbar-end hidden lg:flex">
                    <ul className="menu menu-horizontal px-1">
                        <Link to="/" className='mr-6'>Home</Link>
                        <Link to="/about" className='mr-6'>About</Link>
                        <Link to="/services" className='mr-6'>Our Service</Link>
                        <Link to="/fleet" className='mr-6'>Our Fleet</Link>
                        <Link to="/team" className='mr-6'>Our Team</Link>


                    </ul>
                </div>
                <div className="">
                    <Link to="/contact">
                        <button className='btn btn-success'>Contact</button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Navbar;