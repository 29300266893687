import React from 'react';
import { services } from '../../data/data';


const Counter = () => {
    return (
        <div className='mt-10'>

        <div className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-20'>

            {
                services?.map(service => <>
                    <div className='flex flex-col items-center '>
                        <div className='flex items-center justify-center flex-row gap-3'>

                            <img src={service.img} className="w-[60px]" alt=""/>
                            <h2 className='text-5xl'>{service.title}</h2>

                        </div>
                        <div className='mt-4 text-xl'>
                            <p>{service.subtitle}</p>
                        </div>
                    </div>

                </>)
            }


        </div>
    </div>
    );
};

export default Counter;