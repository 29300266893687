import React from 'react';
import {Link} from 'react-router-dom';
import Button from '../../Shared/Button/Button';
import "./Banner.css"


const Banner = () => {
    return (
        <div>
            <div className='bannerContainer'>
                <div className='h-full flex flex-col justify-center items-center lg:items-start container mx-auto text-white p-5'>
                    <h2 className='text-2xl lg:text-4xl font-bold mb-3'>Pride Shipping Lines</h2>
                    <p className='text-xl'>ENSURING EFFICIENCY IN THE FIELD OF SHIPPING SECTORS IN BANGLADESH</p>
                        <Link to="/about"> <Button>About Us</Button></Link>

                </div>
                <div>
                </div>
            </div>

        </div>
    );
};

export default Banner;