import ship from "../assets/icons/ship.png"
import deepSea from "../assets/icons/cruise.png"
import anchor from "../assets/icons/anchor.png"
import fender from "../assets/icons/yatch.png"
import crew from "../assets/icons/sailor.png"
import wasteOil from "../assets/icons/water-pollution.png"
import others from "../assets/icons/settings.png"

export const services = [
    {
        "id" : 1,
        "icon" :ship,
        "name" : "Port Agency",
        "details" : "Pride Shipping Lines is the busiest shipping agent in Bangladesh. To streamline our service, we maintain excellent team members to co-ordinate different government organization like port authority, custom house, immigration, and etc. to get the NOC for the vessel so that vessel can sail as soon as she is done with cargo operation."
    },
    {
        "id" : 2,
        "icon" : deepSea,
        "name" : "Deep Sea Pilotage",
        "details" : "Pride Shipping Lines maintains a pool of experience pilots to meet owners demand for vessel safety. Every week we engage Master Mariner, UK ticket holder, to ensure safe passage from Kutubdia to Chittagong Outer anchorage and LPG terminal. "
    },
    {
        "id" : 3,
        "icon" : anchor,
        "name" : "STS",
        "details" : "Pride Shipping Lines work with world renowned Fendercare Marine Services to provide STS services in Bangladesh. Pride Shipping Lines provided support to Fendercare Marine to provide STS operation in Petroleum product vessels and LNG vessels in Chittagong port. Project included STS operation of LR2 vessel carrying 142,000 Metric Tons refined petroleum product to MR vessel to load up to 30,000 Metric Tons and LNG project include transfer of 140,000 cbm liquid gas to FSRU. We also provided support for STS cargo bound to Indian ports with our principal."
    },
    {
        "id" : 4,
        "icon" : fender,
        "name" : "Fender",
        "details" : "Pride Shipping Lines has maintains the largest fender stock and regularly supply fenders not only to the vessel calling under our agency but also to vessel calling other agencies. We have our own technician and boat who takes the fender to the vessel and do rigging and de-rigging of the fender to the vessels."
    },
    {
        "id" : 5,
        "icon" : crew,
        "name" : "Crew Change and Medical visit",
        "details" : "We have a separate department for crew change. This department handles crew change for vessels calling under any agency in Bangladesh. Our expert team provides seamless experience for crew joining and repatriation to and from vessel, hotel and airports. Owners over the years trusted us to manage crew handling and we are doing our best to go beyond the expectations of our clients."
    },
    {
        "id" : 6,
        "icon" : wasteOil,
        "name" : "De-slopping/Waste oil removal",
        "details" : "Pride Shipping Lines engages qualified vendors to do de-slopping of the vessel. Every month our vendors are working continuously with all the renowned ship owners to meet their de-slopping needs. Because of the efficient handling of us, owners are regularly working with us for the deslopping operation. We ensure our vendor takes permission from all concerned offices and conduct the operation under the presence of custom official to ensure the compliance with local law. A slop reception certificate is issued after the operation. 24 hours’ notice is required to conduct any de-slopping operation."
    },
    /*{
        "id" : 7,
        "icon" : others,
        "name" : "Other Services",
        "details": "Pride Shipping Lines also provides other services continuously to the vessel and its owners or its representative. With all these services, we work as one-stop service provider for our principals in order to reduce any hassle from their end. Our services include, but not limited to below:",
        "detailsList": [
            " a) Ship Spares Delivery ", " b) Supply of provisions, deck, and engine stores ",
           " c) Bunker co - ordination - Supply, quality and quantity survey ", " d) Lube oil supply",
            " e) De - bunkering: off - specification bunker ", " f) Gas carrier",
            "g) Aerial Photography ", " h) Underwater diving services",
            " i) Emergency tug services ", " j) Fresh Water Supply ",
           " k) Nitrogen purging and other gas supply"
        ]
        
    },*/
]