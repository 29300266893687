import ServiceCardDetail from "../Component/ServiceCardDetail/ServiceCardDetail";
import AboutPages from "../Pages/AboutPage/AboutPages";
import ContactPage from "../Pages/ContactPage/ContactPage";
import FleetPage from "../Pages/FleetPage/FleetPage";
import HomePage from "../Pages/HomePage/HomePage";
import ServicePage from "../Pages/ServicePage/ServicePage";
import TeamPage from "../Pages/TeamPage/TeamPage";

const { createBrowserRouter } = require("react-router-dom");
const { default: Main } = require("../Layout/Main");

export const router = createBrowserRouter([
    {
        path: "/", element: <Main />, children: [
            {
                path: "/", element: <HomePage/>
            },
            
            {
                path: "/about", element: <AboutPages/>
            },
            {
                path: "/services", element: <ServicePage/>
            },
            {
                path: "/servicedetail/:id",
                element: <ServiceCardDetail />
            },
            {
                path: "/fleet", element: <FleetPage/>
            },
            {
                path: "/team", element: <TeamPage/>
            },
            {
                path: "/contact" , element: <ContactPage/> 
            }
        ]
    }
])