import design from "../assets/icons/creativeDesign.png"
import innovative from "../assets/icons/innovative.png"
import functional from "../assets/icons/Functional.png"

export const works = [
    {
        "id": 1,
        "icon": design,
        "title": "Creative",
        // "description" : "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod.."
    },
    {
        "id": 2,
        "icon": innovative,
        "title": "Innovative",
        // "description" : "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod.."
    },
    {
        "id": 3,
        "icon": functional,
        "title": "Functional",
        // "description" : "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod.."
    }
]