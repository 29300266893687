import React from 'react';
import TeamDetails from '../../Component/TeamComponent/TeamDetails';

const TeamPage = () => {
    return (
        <div>
            <TeamDetails/>
        </div>
    );
};

export default TeamPage;